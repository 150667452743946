@import 'variables.scss';

.footer {
  padding: 3rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.footer__title {
  text-align: left;
  color: $white;
}

.footer_link {
  margin: 0.3rem 0rem;
}

.footer_rs {
  margin-top: 3rem;
  text-align: center;
}

.footer_rs > a > svg {
  font-size: $font-size-n12;
  color: $white;
  &:hover {
    color: $fourth-light;
  }
}

.footer_link > a,
svg {
  color: $white;
  font-size: $font-size-n4;
  &:hover,
  svg {
    color: $fourth-light;
  }
}

.footer_link > a > svg {
  color: $white;
  margin-right: 5px;
}

/* XLarge */

@media screen and (max-width: 1800px) {
}
/* Large */

@media screen and (max-width: 1280px) {
  .footer_rs > a > svg {
    font-size: $font-size-n9;
  }
}

/* Medium */

@media screen and (max-width: 980px) {
  .footer_rs > a > svg {
    font-size: $font-size-n5;
  }

  .footer-slogan {
    font-size: $font-size-n1;
  }
}

/* Small */

@media screen and (max-width: 770px) {
  .footer_link > a,
  svg {
    font-size: $font-size-n3;
  }

  .footer_rs > a > svg {
    font-size: $font-size-n9;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
  .footer {
    padding: 3rem 2rem;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
  }
}
