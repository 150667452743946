@import 'variables.scss';

.donate-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 4rem 10rem;
}

.donate-card {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.055);
  background: rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    to top,
    rgba(250, 250, 250, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 80%
  );
}

.donate-card__description > p {
  color: black;
  font-size: $font-size-n4;
  font-weight: $font-weight-normal;
}

.logo-img {
  max-width: 80px;
  min-width: 80px;
  max-height: 80px;
  min-height: 80px;
  margin: 0rem 4rem 2rem;
}

.donate-title {
  font-size: $font-size-n9;
  font-weight: $font-weight-normal;
}

.donate-description > p {
  font-size: $font-size-n4;
  text-align: left;
}

.donate-button {
  display: inline-block;
  margin-top: 30px;
  background: $primary;
  color: #fff;
  text-decoration: none;
  font-weight: $font-weight-ligh;
  font-size: $font-size-n4;
  padding: 1rem 2rem;
  border-radius: 40px;
  &:hover {
    background-color: $primary-dark;
    color: $white;
    border-color: $fifth;
    box-shadow: 10px 11px 24px -16px rgba(0, 0, 0, 0.75);
  }
}

.donate-img-alt {
  display: none;
}

.donate-img {
  display: block;
}

/* XLarge */

@media screen and (max-width: 1800px) {
}

/* Large */

@media screen and (max-width: 1280px) {
  .donate-container {
    padding: 5rem 2rem;
  }
}

/* Medium */

@media screen and (max-width: 980px) {
  .donate-container {
    padding: 2rem 4rem;
  }

  .donate-img{
    display: none;
  }

  .donate-img-alt{
    display: block;
  }

  .donate-title {
    text-align: center;
  }
  
  .donate-description > p {
    font-size: $font-size-n4;
    text-align: center;
  }
  
  .donate-description {
    text-align: center;
  }
}

/* Small */

@media screen and (max-width: 760px) {
  .donate-container {
    padding: 5rem 4rem;
  }

  .donate-card__description > p {
    color: $primary;
    font-size: $font-size-n1;
    font-weight: $font-weight-normal;
  }

  .donate-title {
    font-size: $font-size-n6;
    font-weight: $font-weight-normal;
  }

  .donate-description > p {
    font-size: $font-size-n4;
    text-align: center;
  }
  
  .donate-description {
    text-align: center;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
  .donate-container {
    padding: 2rem 3rem;
  }
}
