.carousel-item--alianza {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  min-width: 5rem;  
  min-height: 30rem;
  max-height: 30rem;
  padding: 5rem 5rem;
}

.carousel-control-prev-icon--ally {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%603588'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
}

.carousel-control-next-icon--ally {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%603588'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
}

.carouselAlly{
  display: block;
}

.carouselAllyMobile{
  display: none;
}

/* XLarge */

@media screen and (max-width: 1800px) {

}
/* Large */

@media screen and (max-width: 1280px) {

}

/* Medium */

@media screen and (max-width: 980px) {
  .carousel-item--alianza {
    padding: 2rem 5rem;
  }
}

/* Small */

@media screen and (max-width: 770px) {

  .carousel-item--alianza {
    padding: 2rem 5rem;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
  .carouselAllyMobile{
    display: block;
  }

  .carouselAlly{
    display: none;
  }

  .carousel-item--alianza {
    padding: 0rem;
  }
}