@import 'variables.scss';

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel-caption--overlay {
  width: 100%;
  height: 100%;
  background: $primary-overlay;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
}

.banner__img {
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
}

.carousel-message {
  max-width: 80%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: baseline;
  position: absolute;
  bottom: 0;
  margin-bottom: 8rem;
  margin-left: 12rem;
  color: $fifth;
  z-index: 2;
}

.carousel-message__principal {
  font-size: $font-size-n7;
  font-weight: $font-weight-bold;
}

.carousel-message__secondary {
  font-size: $font-size-n6;
  font-weight: $font-weight-lighter;
}

.carousel-message__btn {
  font-size: $font-size-n4;
  font-weight: $font-weight-bold !important;
}

.main {
  box-sizing: border-box;
  position: relative;
  max-width: 70%;
  min-width: 70%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  flex-flow: column nowrap;
}

.main__description {
  font-size: $font-size-n4;
  text-align: center;
  font-weight: $font-weight-ligh;
}

.pilar-section {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  max-width: 70%;
  min-width: 70%;
}

.contact-section {
  position: relative;
  margin-top: 2rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-link {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  font-size: $font-size-n7;
  &:hover {
    color: $fourth;
  }
}

.contact-link > svg {
  font-size: 5rem;
}

.testimonio-section {
  padding: 2rem 10rem 10rem 10rem;
}

.ally-section {
  position: relative;
  min-height: 35rem;
  z-index: 1;
}

//background
.momo-bg-01 {
  position: absolute;
  left: 0;
  top: 70rem;
  max-width: 48rem;
  z-index: 0;
}

.momo-bg-02 {
  position: absolute;
  right: 0;
  top: 110rem;
  max-width: 48rem;
  z-index: 0;
}

.momo-bg-03 {
  position: absolute;
  left: -10px;
  top: 240rem;
  max-width: 48rem;
  z-index: 0;
}

/* XLarge */

@media screen and (max-width: 1800px) {
  .carousel-item--banner {
    max-height: 100vh;
    min-height: 100vh;
    box-sizing: border-box;
  }

  .banner__img {
    max-height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
  }

  .carousel-message {
    margin-bottom: 9rem;
    margin-left: 12rem;
  }

  .momo-bg-01 {
    top: 70rem;
    max-width: 40rem;
    z-index: 0;
  }

  .momo-bg-02 {
    top: 140rem;
    max-width: 35rem;
    z-index: 0;
  }

  .momo-bg-03 {
    top: 200rem;
    max-width: 48rem;
    z-index: 0;
  }

  .ally-section {
    min-height: 40rem;
    max-height: 40rem;
  }
}

/* Large */

@media screen and (max-width: 1280px) {
  .carousel-item--banner {
    max-height: 70vh;
    min-height: 70vh;
    box-sizing: border-box;
  }

  .banner__img {
    max-height: 70vh;
    min-height: 70vh;
    min-width: 100vw;
    max-width: 100vw;
  }

  .carousel-message {
    margin-bottom: 6rem;
    margin-left: 8rem;
  }

  .carousel-message__principal {
    font-size: $font-size-n2;
    font-weight: $font-weight-bold;
  }

  .carousel-message__secondary {
    font-size: $font-size-n1;
    font-weight: $font-weight-lighter;
  }

  .testimonio-section {
    padding: 3rem 10rem;
  }

  .momo-bg-01 {
    top: 60rem;
    max-width: 20rem;
  }

  .momo-bg-02 {
    top: 100rem;
    max-width: 30rem;
  }

  .momo-bg-03 {
    top: 200rem;
    left: -20px;
    max-width: 35rem;
  }
}

/* Medium */

@media screen and (max-width: 980px) {
  .carousel-item--banner {
    max-height: 50vh;
    min-height: 50vh;
    box-sizing: border-box;
  }

  .banner__img {
    max-height: 50vh;
    min-height: 50vh;
    min-width: 100vw;
    max-width: 100vw;
  }

  .carousel-message {
    margin-bottom: 4rem;
    margin-left: 7rem;
  }

  .btn-primary {
    padding: 0.5rem 1rem;
  }

  .momo-bg-01 {
    top: 50rem;
    max-width: 15rem;
  }

  .momo-bg-02 {
    top: 80rem;
    max-width: 20rem;
  }

  .momo-bg-03 {
    top: 190rem;
    left: -50px;
    max-width: 30rem;
  }

  .testimonio-section {
    padding: 3rem 4rem;
  }

  .contact-link {
    font-size: $font-size-n6;
  }

  .contact-link > svg {
    font-size: 4rem;
  }
}

/* Small */

@media screen and (max-width: 760px) {
  .carousel-item--banner {
    max-height: 50vh;
    min-height: 50vh;
    box-sizing: border-box;
  }

  .banner__img {
    max-height: 50vh;
    min-height: 50vh;
    min-width: 100vw;
    max-width: 100vw;
  }

  .carousel-message {
    max-width: 75%;
    margin-bottom: 1rem;
    margin-left: 5rem;
  }

  .carousel-message__principal {
    font-size: $font-size-n1;
    font-weight: $font-weight-bold;
  }

  .carousel-message__secondary {
    font-size: $font-size-n0;
    font-weight: $font-weight-lighter;
  }

  .momo-bg-01,
  .momo-bg-02,
  .momo-bg-03 {
    display: none;
  }

  .ally-section {
    min-height: 35rem;
    max-height: 35rem;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
  .carousel-item--banner {
    max-height: 20rem;
    min-height: 20rem;
    box-sizing: border-box;
  }

  .banner__img {
    max-height: 20rem;
    min-height: 20rem;
  }

  .carousel-message {
    max-width: 75%;
    margin-bottom: 1rem;
    margin-left: 3rem;
  }

  .testimonio-section {
    padding: 3rem 0rem;
  }

  .pilar {
    padding: 2rem 0rem;
  }

  .main {
    padding: 2rem 0rem;
  }

  .main__description {
    font-size: $font-size-n3;
  }

  .ally-section {
    min-height: 25rem;
    max-height: 25rem;
  }

  .contact-link {
    font-size: $font-size-n3;
  }

  .contact-link > svg {
    font-size: 3rem;
  }
}
