@import 'variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: $fifth;
}

* {
  box-sizing: border-box;
  text-decoration: none !important;
  font-size: $font-size-n1;
  font-family: 'Nunito', sans-serif;
  line-height: 38px;
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: #888 #555;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card{
  z-index: 1;
}

ul {
  list-style: none;
}

a {
  color: $black;
  &:hover {
    cursor: pointer;
  }
}

button:focus {
  outline: none;
}

.disabled {
  color: grey;
  opacity: 0.6;
}

.invisibility {
  display: none !important;
}

.visibility {
  display: block !important;
}

.img-center {
  display: flex;
  justify-content: center;
  align-self: center;
}

input {
  width: 90%;
  height: 100%;
  border: none;
  border-radius: 0px;
  padding: 1em;
  margin: 0;
  background-color: #ececec;
}

img {
  object-fit: cover;
}

.btn {
  display: flex;
  flex-flow: row nowrap;
  text-align: center;
  justify-content: center;
}

.MuiSvgIcon-root {
  color: white;
  margin: 0rem 0.2rem;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

.title {
  width: 100%;
  font-size: $font-size-n9;
  font-weight: $font-weight-bold;
}

.text-blod {
  font-weight: $font-weight-bold !important;
}


.text-bloder {
  font-weight: $font-weight-bolder;
}

//colors
.color-primary {
  color: $primary-dark;
}

.color-white {
  color: $white;
}

.color-black {
  color: $black;
}

//img sizes
.momo-img-0 {
  max-width: 220px;
  min-width: 220px;
  max-height: 220px;
  min-height: 220px;
  margin: 2rem;
}

.momo-img-01 {
  object-fit: fill;
  max-width: 220px;
  min-width: 220px;
  max-height: 250px;
  min-height: 250px;
  margin: 1rem;
}

.momo-img-02 {
  max-width: 300px;
  min-width: 300px;
  max-height: 200px;
  min-height: 200px;
  margin: 1rem;
}

.momo-img-03 {
  max-width: 280px;
  min-width: 280px;
  max-height: 250px;
  min-height: 250px;
  margin: 1rem;
}

.momo-img-04 {
  max-width: 35rem;
  margin: 2rem;
}

.momo-img-05 {
  max-width: 48rem;
  min-width: 48rem;
  margin: 2rem;
}

.img-revert {
  transform: scaleX(-1);
}

//Overwrite font-sizes
p{
  white-space: pre-line;
}

.fs-1 {
  font-size: $font-size-n1 !important;
}

.fs-2 {
  font-size: $font-size-n2 !important;
}

.fs-3 {
  font-size: $font-size-n3 !important;
}

.fs-4 {
  font-size: $font-size-n4 !important;
}

.fs-5 {
  font-size: $font-size-n5 !important;
}

.fs-6 {
  font-size: $font-size-n6 !important;
}

.fs-7 {
  font-size: $font-size-n7 !important;
}

//font wigth
.fw-1 {
  font-weight: $font-weight-lighter;
}

.fw-2 {
  font-weight: $font-weight-ligh;
}

.fw-3 {
  font-weight: $font-weight-normal;
}

.fw-4 {
  font-weight: $font-weight-bold;
}

.fw-5 {
  font-weight: $font-weight-bolder;
}

//Overwrite padding size

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

//Overwrite bootstrap colors

.bg-primary {
  background-color: $primary !important;
}

.bg-primary-dark {
  background-color: $primary-dark !important;
}

.bg-primary-light {
  background-color: $primary-overlay !important;
}

.bg-secondary {
  background-color: $second !important;
}

.bg-thrid {
  background-color: $thrid !important;
}

.bg-fourth {
  background-color: $fourth !important;
}

.bg-fifth {
  background-color: $fifth !important;
}

.bg-fifth-light {
  background-color: $thrid-overlay !important;
}

.bg-sixth {
  background-color: $sixth !important;
}

.bg-seventh {
  background-color: $seventh !important;
}

.btn-primary {
  background-color: $fifth;
  font-weight: $font-weight-bold !important;
  color: $primary-dark;
  border-color: transparent;
  padding: 1rem 2rem;
  border-radius: 40px;
  margin: 2rem 0rem;
  &:hover {
    background-color: $white;
    color: $primary-dark;
    border-color: $fifth;
    box-shadow: 10px 11px 24px -16px rgba(0, 0, 0, 0.75);
  }
}

.btn-second {
  background-color: $primary;
  color: $white;
  border-color: $fifth;
  padding: 1rem 2rem;
  border-radius: 40px;
  margin: 2rem 0rem;
  &:hover {
    background-color: $primary-dark;
    border-color: $primary-light;
    color: $white;
    box-shadow: 10px 11px 24px -16px rgba(0, 0, 0, 0.75);
  }
}

/*Modal*/

.donate-modal {
  width: 40%;
}

.donate-modal__button-primary {
  background-color: $primary;
  color: $white;
  border-color: $fifth;
  padding: 1rem 2rem;
  border-radius: 40px;
  margin: 1rem 0rem;
  &:hover {
    background-color: $primary-dark;
    border-color: $primary-light;
    color: $white;
    box-shadow: 10px 11px 24px -16px rgba(0, 0, 0, 0.75);
  }
}

.donate-modal__button-close {
  display: flex;
  justify-items: end;
  justify-content: end;
  margin-top: 0.5rem;
}

.donate-modal__button-close > svg {
  font-size: $font-size-n6;
}

button:focus {
  outline: none;
}

@media screen and (max-width: 1280px) {
  .momo-img-05 {
    max-width: 40rem;
    min-width: 40rem;
  }
}

/* Medium */
@media screen and (max-width: 980px) {
  .momo-img-05 {
    max-width: 35rem;
    min-width: 35rem;
  }

  .text-center-mobile {
    text-align: center;
  }

  .line-height-carrusel{
    line-height: 15px;
  }
}

@media screen and (max-width: 760px) {
  .momo-img-01 {
    max-width: 190px;
    min-width: 190px;
    max-height: 190px;
    min-height: 190px;
    margin: 2rem;
  }

  .momo-img-05 {
    max-width: 25rem;
    min-width: 25rem;
  }
  .title {
    font-size: $font-size-n6;
  }

  .pt-6 {
    padding-top: 3rem;
  }

  .pb-6 {
    padding-bottom: 3rem;
  }

  .mb-6 {
    margin-bottom: 3rem;
  }

  .donate-modal {
    width: 70%;
  }

  .fs-1 {
    font-size: $font-size-n0 !important;
  }
  
  .fs-2 {
    font-size: $font-size-n1 !important;
  }
  
  .fs-3 {
    font-size: $font-size-n2 !important;
  }
  
  .fs-4 {
    font-size: $font-size-n3 !important;
  }
  
  .fs-5 {
    font-size: $font-size-n4!important;
  }
  
  .fs-6 {
    font-size: $font-size-n5 !important;
  }
  
  .fs-7 {
    font-size: $font-size-n6 !important;
  }
  p{
  line-height: 32px;
  }

  .btn-primary {
    margin: 1rem 0rem 2rem 0rem;
  }
}
@media screen and (max-width: 480px) {
  .momo-img-0 {
    max-width: 120px;
    min-width: 120px;
    max-height: 120px;
    min-height: 120px;
    margin: 2rem;
  }

  .momo-img-02 {
    max-width: 200px;
    min-width: 200px;
    max-height: 140px;
    min-height: 140px;
    margin-top: 2rem;
  }

  .momo-img-05 {
    max-width: 20rem;
    min-width: 20rem;
  }
}
