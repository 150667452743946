//COLORS
$black: #000;
$white: rgb(255, 255, 255);
$primary: rgb(139, 89, 186);
$second: rgb(128, 79, 133);
$thrid: rgb(232, 231, 218);
$fourth: #dfa565;
$fifth: rgb(238, 237, 237);
$sixth: rgb(71, 71, 71);
$seventh: rgb(242, 243, 237);

$primary-light: #d8cce1;
$fourth-light: rgb(245, 187, 125);
$thrid-overlay: rgb(248, 248, 248);
$primary-overlay: rgba(139, 89, 186, 0.5);

$primary-dark: rgb(96, 53, 136);

//FONT SIZE
$font-size-n0: 0.7rem;
$font-size-n1: 0.9rem;
$font-size-n2: 1rem;
$font-size-n3: 1.1rem;
$font-size-n4: 1.3rem;
$font-size-n5: 1.5rem;
$font-size-n6: 1.9rem;
$font-size-n7: 2.1rem;
$font-size-n8: 2.3rem;
$font-size-n9: 2.5rem;
$font-size-n10: 2.7rem;
$font-size-n11: 2.9rem;
$font-size-n12: 3.2rem;
$font-size-n13: 3.5rem;

//FONT WEIGHT
$font-weight-lighter: 300;
$font-weight-ligh: 400;
$font-weight-normal: 550;
$font-weight-bold: 800;
$font-weight-bolder: 900;
