@import 'variables.scss';

.about-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.about-hover-text {
  &:hover {
    color: $fourth;
  }
}

.team__item {
  min-height: 480px;
  max-height: 480px;
  width: 30%;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
  background-position: center;
  text-align: center;
  background-size: cover;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(124, 23, 196, 0.5);
    content: '';
    z-index: -1;
    opacity: 0;
    -webkit-transition: all, 0.5s, ease-out, 0.5s;
    -o-transition: all, 0.5s, ease-out, 0.5s;
    transition: all, 0.5s, ease-out, 0.5s;
  }
  &:hover:after {
    opacity: 1;
  }
  &:hover .team__text {
    top: 0;
  }
  &:hover .team__text .team__title {
    border-bottom: 1px solid rgba(183, 183, 183, 0.2);
    padding: 0 0 26px;
    margin-bottom: 26px;
    opacity: 0;
    visibility: hidden;
    position: relative;
    bottom: initial;
    opacity: 1;
    visibility: visible;
  }
  &:hover .team__text p {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s ease 0.3s;
    -o-transition: all 0.5s ease 0.3s;
    transition: all 0.5s ease 0.3s;
  }
}

.team__icons > a > svg {
  color: white;
  margin: 0 10px;
  &:hover {
    color: $fourth;
  }
}

.team__item:hover .team__text .team__social {
  opacity: 1;
  visibility: visible;
  bottom: 34px;
  -webkit-transition: all 0.5s ease 0.5s;
  -o-transition: all 0.5s ease 0.5s;
  transition: all 0.5s ease 0.5s;
}

.team__text {
  height: 100%;
  top: 361px;
  position: relative;
  padding: 35px 10px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.team__text > p {
  color: #e4e4e4;
  opacity: 0;
  visibility: hidden;
  position: relative;
  top: 50px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 260px;
  font-size: $font-size-n2;
  padding: 0rem 1rem;
  font-weight: $font-weight-ligh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $fourth-light;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    background: $fourth;
  }
}

.team__text .team__title {
  border-bottom: 1px solid rgba(183, 183, 183, 0.2);
  padding: 0 0 26px;
  margin-bottom: 26px;
  position: relative;
}

.team__text .team__title h4 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 6px;
}

.team__text .team__title span {
  font-size: 15px;
  color: #fff;
}

.team__text .team__social {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.team__text .team__social a {
  display: inline-block;
  color: #fff;
  margin-right: 34px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.team__text .team__social a:last-child {
  margin-right: 0;
}

/* XLarge */

@media screen and (max-width: 1800px) {
}

/* Large */

@media screen and (max-width: 1280px) {
}

/* Medium */

@media screen and (max-width: 980px) {
  .team__item {
    width: 45%;
  }
}

/* Small */

@media screen and (max-width: 760px) {
  .team__item {
    width: 80%;
    margin-left: 10%;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
}

