@import 'variables.scss';

.header {
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  position: fixed;
  background-color: transparent;
  z-index: 4;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0rem 2rem;
}

.header_color {
  background-color: $primary-overlay;
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  position: fixed;
  z-index: 3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0rem 2rem;
}

.header__nav {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.header__nav > li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header__nav > li > a {
  font-weight: $font-weight-bold;
  font-size: $font-size-n4;
  color: $white;
  &:hover {
    color: $fourth-light;
  }
}

.header_logo {
  max-width: 9rem;
  align-self: center;
}

.btn-language {
  text-align: center;
  min-width: 50px;
  min-height: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
  border-color: white;
  font-size: $font-size-n4;
}

/*Menú movil*/
.header-mobile {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem 1rem;
  transition: all 1s;
  position: fixed;
  z-index: 4;
  background-color: $primary;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-mobile > ul {
  padding: 0;
  margin-top: 1rem;
}

.header-mobile__link {
  margin-top: 10px;
}

.header-mobile__link > a {
  font-size: $font-size-n4;
  color: $white;
  &:hover {
    color: $fourth;
  }
}

.btn-language--mobile {
  position: absolute;
  bottom: 20px;
  right: 15px;
}

.no-display {
  display: none !important;
}

/*hamburger*/

#hamburger {
  width: 100%;
  display: none;
}

#hamburger button {
  background: none;
  border: 0px;
  float: right;
  cursor: pointer;
  padding: 0px;
  width: 60px;
  height: 60px;
}

#hamburger button span {
  border-radius: 5px;
  background: white;
  display: block;
  width: 60%;
  margin: 1em auto;
  height: 2px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#hamburger button.open .top-line {
  animation-name: top-line-animation;
}

#hamburger button.open .middle-line {
  animation-name: middle-line-animation;
}

#hamburger button.open .bottom-line {
  animation-name: bottom-line-animation;
}

#hamburger button .top-line {
  animation-name: top-line-animation-close;
}

#hamburger button .middle-line {
  animation-name: middle-line-animation-close;
}

#hamburger button .bottom-line {
  animation-name: bottom-line-animation-close;
}

@keyframes top-line-animation {
  50%,
  100% {
    margin: 0 auto;
    transform: translateY(2px) rotate(-45deg);
  }
}

@keyframes bottom-line-animation {
  50%,
  100% {
    margin: 0 auto;
    transform: translateY(-2px) rotate(45deg);
  }
}

@keyframes middle-line-animation {
  20%,
  100% {
    margin: 0 auto;
    width: 0;
    opacity: 0;
  }
}

@keyframes top-line-animation-close {
  0% {
    margin: 0 auto;
    transform: translateY(2px) rotate(-45deg);
  }
  50%,
  100% {
    margin: 5px auto;
    transform: translateY(0px) rotate(0deg);
  }
}

@keyframes middle-line-animation-close {
  0% {
    margin: 0 auto;
    width: 0;
    opacity: 0;
  }
  20%,
  100% {
    margin: 0 auto;
    width: 60%;
    opacity: 1;
  }
}

@keyframes bottom-line-animation-close {
  0% {
    margin: 0 auto;
    transform: translateY(-2px) rotate(45deg);
  }
  50%,
  100% {
    margin: 5px auto;
    transform: translateY(0px) rotate(0deg);
  }
}

@keyframes bottom-line-animation-close {
  0% {
    margin: 0 auto;
    transform: translateY(-2px) rotate(45deg);
  }
  50%,
  100% {
    margin: 5px auto;
    transform: translateY(0px) rotate(0deg);
  }
}

/* Medium */

@media screen and (max-width: 980px) {
}

/* Small */

@media screen and (max-width: 770px) {
}

/* XSmall */

@media screen and (max-width: 480px) {
  #hamburger {
    display: block;
  }

  .header__nav {
    display: none;
  }
}
