@import 'variables.scss';


.testimonio-title {
  position: relative;
  z-index: 1;
}

.testimonio-card {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 40rem;
  max-height: 40rem;
  text-align: center;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  background-color: $primary-dark;
  border-radius: 5px;
  padding: 1rem 3rem;
  margin: 0rem 10rem;
}

.testimonio-card__title > p {
  font-size: $font-size-n5;
  font-weight: $font-weight-normal;
}

.testimonio-card__text {
  color: white;
  font-size: $font-size-n4;
  font-weight: $font-weight-ligh;
  line-height: 30px;
  margin: 2rem 0rem;
  padding: 0px 10px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $fourth-light;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    background: $fourth;
  }
}

/* XLarge */

@media screen and (max-width: 1800px) {
  .testimonio-card {
    margin: 0rem 5rem;
  }
}
/* Large */

@media screen and (max-width: 1280px) {
  .testimonio-card {
    margin: 0rem 3rem;
  }
}

/* Medium */

@media screen and (max-width: 980px) {
}

/* Small */

@media screen and (max-width: 770px) {
  .testimonio-card {
    margin: 0rem 1rem;
    padding: 1rem;
    height: auto;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
  .testimonio-card {
    margin: 0rem 1rem;
    padding: 1rem;
    height: auto;
  }

  .testimonio-card__text {
    font-size: $font-size-n1;
  }
}
